import React, { useEffect, useState } from "react";
import { withRouter, useRouter } from "next/router";
import { ThemeProvider } from 'styled-components';
import * as ga from "../lib/ga";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

import "bootstrap/dist/css/bootstrap.min.css";
import "../css/navbar.css";
import "../css/home.css";
import "../css/App.css";
import "../css/index.css";
import "../css/form.css";

import "../css/payment.css";
import "../css/pricing.css";
import "../css/terms.css";
import "../css/thank-you.css";

import { theme } from "../V2/theme";
import { GlobalStyle as GlobalStyleFederal } from "../V2/components/V2-federal/styled/GlobalStyle";
import { GlobalStyle as GlobalStyleReflection1 } from "../V2/components/V2-reflection-1/styled/GlobalStyle";
import { GlobalStyle as GlobalStyleReflection2 } from "../V2/components/V2-reflection-2/styled/GlobalStyle";
import wrapper from "../store/store";

import Header from "../component/Header";
import TaxidFooter from "../component/TaxidFooter";
import Footer from "../component/Footer";
import Clicks from "../component/analytics/Clicks";
import ReactGA from "../component/analytics/ReactGA";
import { Seo } from "../component/SEO";

import Layout from "../V2/components/Layout";
import LayoutFederal from "../V2/components/V2-federal/Layout";
import LayoutIncorps from "../V2/components/V2-incorps/Layout";
import LayoutReflection1 from "../V2/components/V2-reflection-1/Layout";
import LayoutReflection2 from "../V2/components/V2-reflection-2/Layout";
import OldLayout from "../V2/components/OldLayout";

function App({ Component, pageProps }) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid") {
    import("../css/taxid.css");
  } else if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein") {
    import("../css/taxid-ein.css");
  } else if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "irs" || process.env.NEXT_PUBLIC_REACT_APP_SKIN === "irsein") {
    import("../css/irs.css");
  }

  const { NEXT_PUBLIC_REACT_APP_SKIN } = process.env;
  const { NEXT_PUBLIC_URL } = process.env;
  const { NEXT_PUBLIC_REACT_APP_HJID } = process.env;
  const { NEXT_PUBLIC_REACT_APP_HJSV } = process.env;


  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_REACT_APP_GTM,
      preview: NEXT_PUBLIC_URL,
    };
    setTimeout(() => {
      setLoading(false);
    }, 500);
    Clicks(router);

    TagManager.initialize(tagManagerArgs);

    if(process.env.NEXT_PUBLIC_REACT_APP_GA !=="") {
      const handleRouteChange = (url) => {
        ga.pageview(url);
      };
      router.events.on("routeChangeComplete", handleRouteChange);
      ReactGA.pageview(router.pathname);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router, NEXT_PUBLIC_REACT_APP_SKIN, NEXT_PUBLIC_URL]);

  useEffect(() => {
    hotjar.initialize(NEXT_PUBLIC_REACT_APP_HJID, NEXT_PUBLIC_REACT_APP_HJSV);
  }, [NEXT_PUBLIC_REACT_APP_HJID, NEXT_PUBLIC_REACT_APP_HJSV]);

  if (loading) {
    return null;
  }

  if (router.asPath.includes("incorps") || router.asPath.includes("INCLLC")) {
    return (
      <ThemeProvider theme={theme}>
        <LayoutIncorps>
          <Component {...pageProps} />
        </LayoutIncorps>
      </ThemeProvider>
    );
  }
  
  if (NEXT_PUBLIC_REACT_APP_SKIN === "taxnumber") {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    );
  }

  if (NEXT_PUBLIC_REACT_APP_SKIN === "federaltaxid") {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyleFederal />
        <LayoutFederal>
          <Component {...pageProps} />
        </LayoutFederal>
      </ThemeProvider>
    );
  }

  if (NEXT_PUBLIC_REACT_APP_SKIN === "reflection-1") {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyleReflection1 />
        <LayoutReflection1>
          <Component {...pageProps} />
        </LayoutReflection1>
      </ThemeProvider>
    );
  }

  if (NEXT_PUBLIC_REACT_APP_SKIN === "reflection-2") {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyleReflection2 />
        <LayoutReflection2>
          <Component {...pageProps} />
        </LayoutReflection2>
      </ThemeProvider>
    );
  }

  if (
    router.asPath.includes("LLCUpsell") ||
    router.asPath.includes("LLCInc") ||
    router.asPath.includes("TrademarkUpsell") ||
    router.asPath.includes("BOIUpgrade")
  ) {
    return (
      <ThemeProvider theme={theme}>
        <OldLayout>
          <Component {...pageProps} />
        </OldLayout>
      </ThemeProvider>
    );
  }

  return (
    <main
      className={
        NEXT_PUBLIC_REACT_APP_SKIN === "taxid" ||
        NEXT_PUBLIC_REACT_APP_SKIN === "irs"
          ? NEXT_PUBLIC_REACT_APP_SKIN
          : NEXT_PUBLIC_REACT_APP_SKIN === "irsein" ? "irs" : NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein" ? "taxid" :""
      }
    >
      <Seo
        title={`${
          process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid" || process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein" ? "Taxid" : "Irs"
        } | Home`}
        description=""
      />

      <Header />

      <div className="content-wrapper">
        <div className="content">
          <Component {...pageProps} />
        </div>
      </div>
      {NEXT_PUBLIC_REACT_APP_SKIN === "taxid" || NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein" ? <TaxidFooter /> : <Footer />}
    </main>
  );
}

export default wrapper.withRedux(withRouter(App));
